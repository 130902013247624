import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import SesManagementMeritArea from './ses-managementMeritArea'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'

const Wrapper = styled.section`
  font-weight: bold;
  max-width: 1120px;
  margin: 0 auto;
  .content-area {
    position: relative;
  }
  .back-ground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .triangle {
      content: '';
      width: 0;
      height: 0;
      border-left: 600px solid transparent;
      border-right: 600px solid transparent;
      border-top: 600px solid #deefee;
      margin-top: -5vw;
      &:nth-of-type(1) {
        opacity: 0.3;
      }
      &:nth-of-type(2) {
        opacity: 0.6;
      }
      &:nth-of-type(3) {
        opacity: 0.9;
      }
    }
  }
  ${BreakPoints.largest} {
    .back-ground {
      .triangle {
        border-left: 500px solid transparent;
        border-right: 500px solid transparent;
        border-top: 500px solid #deefee;
        margin-top: -5vw;
      }
    }
  }
  ${BreakPoints.xxLarge} {
    margin: 0 30px;
  }

  ${BreakPoints.xLarge} {
    .back-ground {
      .triangle {
        border-left: 40vw solid transparent;
        border-right: 40vw solid transparent;
        border-top: 40vw solid #deefee;
        margin-top: -5vw;
      }
    }
  }

  ${BreakPoints.medium} {
    .merit-area:nth-of-type(2) {
      flex-direction: column-reverse;
    }
  }
`

/**
 * 案件管理機能紹介ページ-導入メリット
 * @constructor
 */
const SesManagementMerit = () => (
  <Wrapper>
    <OfficialCommonH3Set label="導入メリット" className="fade-in-auto" />

    <div className="p-section-inner content-area">
      <div className="back-ground">
        <div className="triangle" />
        <div className="triangle" />
        <div className="triangle" />
      </div>

      <SesManagementMeritArea
        number="01"
        label={'案件に関する膨大な情報を\n1つにまとめて管理業務の負荷を軽減'}
        img="sesManagementMerit01"
        imageRight
        icon="sesManagementMeritIcon01"
        merit={
          '注視したい技術者に\nウォッチャー機能で\nマークをつけて\nさらに便利！'
        }
      >
        {
          'バラバラに管理しがちな情報を一括で登録できるので案件情報ごとに細かい契約内容と請求情報を1画面で確認できます。\n類似案件があれば、コピー機能を使って案件情報を登録できるので0から情報を入力する必要がありません。'
        }{' '}
      </SesManagementMeritArea>
      <SesManagementMeritArea
        number="02"
        label={'単価目標管理で単価アップ交渉の\nタイミングも簡単に把握'}
        img="sesManagementMerit02"
        imageLeft
        icon="sesManagementMeritIcon02"
        merit={
          '業界の経験年数や\n技術者本人の年齢で\n細かく単価目標を\n設定可能！'
        }
      >
        {
          '予め経験年数や年齢別にモデル単価設定ができ、各技術者の実単価を一覧で比較することができます。\n\nモデル単価を下回っている場合、そこが自動的にクローズアップされるので、次に取るべきアクションが策定しやすくなります。'
        }{' '}
      </SesManagementMeritArea>
      <SesManagementMeritArea
        number="03"
        label={'売上データを集計し\n自動でグラフを生成'}
        img="sesManagementMerit03"
        imageRight
        icon="sesManagementMeritIcon03"
        merit={
          '生成されたグラフへ\nフィルター機能を使えば\n自分の見たい情報の\nグラフに変身！'
        }
      >
        {
          '登録されている案件情報から、会社全体の売上や取引先、部門単位の売上個人の単価推移のグラフを自動で生成します。\nデータを分析するための、煩わしいグラフ作成の手間もなくなります。'
        }
      </SesManagementMeritArea>
    </div>
  </Wrapper>
)

export default SesManagementMerit
