import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFaqItems from '../molecules/officialFaqItems'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  text-align: center;
  padding: 80px 20px;

  .p-section-faq {
    text-align: left;
    max-width: 1080px;
    margin: 0 auto;
  }

  .modal-btn {
    position: relative;
    margin: 5px 0 0 20px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -15px;
      border-left: 8px solid #3ec7b3;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }

    a {
      text-decoration: underline solid rgba(255, 255, 255, 0);
      transition: 0.3s all;
      display: inline-block;
      width: unset;
      height: unset;
      padding: 0;
      opacity: 1;

      span {
        font-size: 15px;
        font-weight: normal;
      }

      &:before {
        display: none;
      }

      &:hover {
        opacity: 0.6;
        text-decoration: underline solid #3abd9e !important;

        &:before {
          display: none;
        }
      }
    }

    img {
      display: none !important;
    }
  }

  .text-wrap {
    font-weight: bold;
    text-align: start;
    margin: 30px 20px;

    p {
      line-height: 30px;
    }

    span {
      color: #f2784c;
    }

    .company-setting-link {
      display: block;
      margin: 20px 0;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * 案件管理機能紹介ページ-FAQ
 * @constructor
 */
const SesManagementFaq = () => {
  const routeParam = useRouteParam('/function')
  return (
    <>
      <Wrapper>
        <OfficialCommonH3Set label="FAQ" className="anim-fade-up" />
        <div className="p-section-faq anim-fade-up">
          <OfficialFaqItems
            question="案件管理でできることを教えてください。"
            answer="SES案件管理機能では、以下のように”SES(客先常駐)”に関わる様々な情報を管理することが可能です。"
            idName="matter-01"
          >
            <p>
              ・SES案件及び契約の情報登録、検索、出力
              <br />
              ・契約更新の通知
              <br />
              ・営業情報の共有、単価履歴の管理
              <br />
              ・単価目標の設定および管理
              <br />
              ・全社売上や顧客単位の売上、個人の売上推移などのデータ分析
              <br />
            </p>
          </OfficialFaqItems>
          <OfficialFaqItems
            question="パートナーの案件も管理できますか？"
            answer={
              'パートナーの案件も管理可能です。\n案件の新規登録時に「所属」の項目で「パートナー」または「個人事業主」を選択してください。'
            }
            idName="matter-02"
          />
          <OfficialFaqItems
            question="どの権限のユーザがSES案件管理機能を利用できますか？"
            answer={
              '既存権限では「アカウントオーナー」「システム管理者」「上位管理者」「マネージャー」の権限を持つユーザがご利用いただけます。\nまたオプションを有効化すると「SES営業」権限を新たに設定することができます。'
            }
            idName="matter-03"
          />
          <OfficialFaqItems
            question="SES案件管理機能を試してみたいです。"
            answer={
              '無料トライアル期間中でもSES案件管理機能をご利用いただくことは可能です。\nお試しでのご利用を希望される場合は、「お支払い」より「SES案件管理オプション」の利用開始手続きをしてください。'
            }
            idName="matter-04"
          >
            <p>
              ※オプション利用の詳細は「
              <Link to="#ses-management-how-to">利用方法</Link>
              」をご確認ください。
            </p>
          </OfficialFaqItems>
          <OfficialFaqItems
            question="SES案件管理機能の利用に費用は掛かりますか？"
            answer="Enterpriseプランをご利用中の場合、オプション基本料金は無料でご利用いただくことができます。
          Businessプランご利用の場合は月額10,000円(税抜)でご利用いただけます。。"
            idName="matter-05"
          >
            <p>
              詳細は「
              <Link to="#ses-management-option-price">オプション利用料金</Link>
              」をご確認ください。
            </p>
          </OfficialFaqItems>
          <OfficialFaqItems
            question="お支払いにオプション機能の表示がありません。どうしたらいいですか？"
            answer="「企業設定」で「「派遣」「客先常駐/SES」が事業内容に含まれるため、拡張機能を利用する」に設定されている企業に限り、お支払いに「オプション」が表示されます。"
            idName="matter-06"
          >
            <p>
              ※オプション利用の詳細は「
              <Link to="#ses-management-how-to">利用方法</Link>
              」をご確認ください。
            </p>
            {/* <div className="text-wrap"> */}
            {/*   <p> */}
            {/*     <span>各種設定</span> ＞ <span>企業設定</span> ＞{' '} */}
            {/*     <span>特定業種向け機能設定</span> で <br /> */}
            {/*     <span> */}
            {/*       「派遣」「客先常駐/SES」が事業内容に含まれるため、拡張機能を利用する */}
            {/*     </span> */}
            {/*     に設定します */}
            {/*   </p> */}
            {/*   <a */}
            {/*     href="https://app.kintaicloud.jp/manage/company" */}
            {/*     target="_blank" */}
            {/*     rel="noreferrer" */}
            {/*     className="company-setting-link" */}
            {/*   > */}
            {/*     ▶ 企業設定 */}
            {/*   </a> */}
            {/* </div> */}
          </OfficialFaqItems>
          <OfficialFaqItems
            question="常駐者の請求書の発行はできますか？"
            answer={
              'β版では請求書の発行機能はございませんが、2024年のリリースを目指し、現在鋭意開発中です。\n※リリース時期は予告なく変更される可能性があります。'
            }
            idName="matter-07"
          />
          <OfficialFaqItems
            question="オプションを解除はすぐにできますか？"
            answer="オプション機能は「お支払い」からいつでも解除することが可能です。"
            idName="matter-08"
          />
        </div>
        <div className="common-button">
          <OfficialCommonBtn
            size="mega"
            className="anim-fade-up"
            onClick={() => {
              navigate(routeParam)
            }}
          >
            その他の機能を見る
          </OfficialCommonBtn>
        </div>
      </Wrapper>
    </>
  )
}

export default SesManagementFaq
