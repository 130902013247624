import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  display: inline-block;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  background-color: #3ec7b3;
  border: 2px solid #8bdcd0;
  width: fit-content;
  padding: 3px 20px;
  border-radius: 8px;
  margin: 0 5px;

  ${BreakPoints.medium} {
  }
  ${BreakPoints.smallest} {
  }
`

type Props = {
  /** タイトル */
  label: string
}

/**
 * 代理申請機能紹介ページ-利用方法ラベル
 * @constructor
 */

const SesManagementHowtoButtonLabel = (props: Props): JSX.Element => {
  const { label = '' } = props

  return (
    <Wrapper>
      <p>{label}</p>
    </Wrapper>
  )
}

export default SesManagementHowtoButtonLabel
