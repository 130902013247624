import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: start;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #d7d7d7;

  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    // border: 1px solid #ababab;
  }

  li {
    flex: 1;
    // border: 1px solid #ff0000;
    height: 60px;
    border-top: 1px solid #ebebeb;
    border-right: 1px dashed #ebebeb;
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    // border: 1px solid #0000ff;
    border-radius: 5px;
    height: calc(100% - 20px);
    text-align: center;
    line-height: 38px;
  }

  .left-cell {
    flex: 2;
    min-width: 205px;

    p {
      justify-content: flex-start;
      background-color: #f0f0f0;
      padding-left: 10px;
      margin-left: 0;
    }
  }

  .employees {
    margin: 10px 10px 10px 0;
    display: flex;
    // border: 1px solid #0000ff;
    border-radius: 5px;
    height: calc(100% - 10px);
    background-color: #f0f0f0;
  }

  .employees-cell {
    flex: 2;
    height: 100%;
    border-bottom: none;
    min-width: 205px;
  }

  .line-1 {
    li {
      border: none;
    }

    margin-bottom: 5px;

    .null-cell p {
      background-color: #ffffff;
    }

    .business-cell p {
      background-color: #ffebb5;
      font-size: 20px;
    }

    .enterprise-cell p {
      background-color: #ffd167;
      font-size: 20px;
    }

    p {
      margin: 0 5px;
      height: 100%;
      line-height: 58px;
      border-radius: 2px;
    }
  }

  .line-4 {
    height: 120px;

    div {
      flex: 1;
      border-bottom: 1px solid #ebebeb;
    }
  }

  .employees-left {
    flex: 1;
    margin: 0;
    padding-left: 10px;
    // border: 1px solid #adff2f;
    height: 100%;
    padding-top: 16px;
    align-items: start;
    text-align: start;
    border-radius: 0;
    justify-content: flex-start;
  }

  .employees-right {
    width: 170px;

    p {
      padding-left: 10px;
      padding-top: 8px;
      height: 50%;
      margin: 0;
      border-radius: 0;
      align-items: start;
      text-align: start;
      justify-content: flex-start;
      border-left: 2px solid #ffffff;
    }

    p:nth-of-type(1) {
      border-bottom: 2px solid #ffffff;
    }
  }

  // テキストが2行あるときの行の高さ
  .text-2line {
    line-height: 19px;
  }

  ${BreakPoints.large} {
    p {
      font-size: 12px;
    }
  }

  ${BreakPoints.medium} {
    margin: 0 20px;

    p {
      font-size: 10px;
    }

    .line-1 {
      li {
        height: 40px;
      }

      .business-cell p,
      .enterprise-cell p {
        font-size: 12px;
      }
    }

    .employees-left {
      max-width: 80px;
      padding-right: 10px;
    }
  }

  ${BreakPoints.smallest} {
  }
`

/**
 * 代理申請機能紹介ページ-利用方法ラベル
 * @constructor
 */

const SesManagementOptionPriceTable = (): JSX.Element => (
  // const {  = '' } = props

  <Wrapper>
    <ul className="line-1">
      <li className="left-cell null-cell">
        <p />
      </li>
      <li className="business-cell">
        <p>Business</p>
      </li>
      <li className="enterprise-cell">
        <p>Enterprise</p>
      </li>
    </ul>
    <ul className="line-2">
      <li className="left-cell">
        <p>オプション基本料金（月額）</p>
      </li>
      <li>
        <p>10,000円</p>
      </li>
      <li>
        <p>無料</p>
      </li>
    </ul>
    <ul className="line-3">
      <li className="left-cell">
        <p>社員の案件管理</p>
      </li>
      <li>
        <p>無料</p>
      </li>
      <li>
        <p>無料</p>
      </li>
    </ul>
    <ul className="line-4">
      <li className="employees-cell">
        <div className="employees">
          <p className="employees-left text-2line">
            パートナー/個人事業主の
            <br />
            案件管理
          </p>
          <div className="employees-right">
            <p>社員数まで</p>
            <p className="text-2line">
              101人以上または
              <br />
              社員数を超えた人数
            </p>
          </div>
        </div>
      </li>
      <div>
        <li>
          <p>無料</p>
        </li>
        <li>
          <p>-</p>
        </li>
      </div>
      <div>
        <li>
          <p>無料</p>
        </li>
        <li>
          <p>100円/人</p>
        </li>
      </div>
    </ul>
  </Wrapper>
)

export default SesManagementOptionPriceTable
