import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: start;
  color: #ffffff;

  h1 {
    font-size: 40px;
    margin-top: -3px;
  }

  h3 {
    font-size: 24px;
  }

  .number-area {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1cb98f;
    border-radius: 50%;
    height: 60px;
    width: 60px;
  }

  .label-area {
    padding: 0 15px;
    margin-left: -10px;
    background-color: #1cb98f;
    position: relative;
    height: 44px;
    line-height: 42px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -22.9px;
      border: 22px solid transparent;
      border-left: 23px solid #1cb98f;
      border-right: none;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: -0.7px;
      border: 22px solid #1cb98f;
      border-right: none;
      z-index: -1;
    }
  }

  ${BreakPoints.medium} {
    h1 {
      font-size: 30px;
      margin-top: -3px;
    }

    h3 {
      font-size: 16px;
    }

    .number-area {
      height: 40px;
      width: 40px;
    }

    .label-area {
      height: 30px;
      line-height: 30px;

      &:before {
        right: -16.9px;
        border: 15px solid transparent;
        border-left: 17px solid #1cb98f;
        border-right: none;
      }

      &:after {
        border: 15px solid #1cb98f;
      }
    }
  }

  ${BreakPoints.smallest} {
  }
`

type Props = {
  // /** クラス名 */
  // className?: string
  /** 番号 */
  number: string
  /** タイトル */
  label: string
}

/**
 * 代理申請機能紹介ページ-利用方法ラベル
 * @constructor
 */

const SesManagementHowtoLabel = (props: Props): JSX.Element => {
  const { number, label = '' } = props

  return (
    <Wrapper>
      <div className="number-area">
        <h1>{number}</h1>
      </div>
      <div className="label-area">
        <h3>{label}</h3>
      </div>
    </Wrapper>
  )
}

export default SesManagementHowtoLabel
