import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import OfficialLayout from '../../components/organisms/officialLayout'
import OfficialLowHeader from '../../components/organisms/officialLowHeader'
import SEO from '../../components/atoms/seo'
import SesManagementTop from '../../components/organisms/ses-managementTop'
import SesManagementRecommend from '../../components/organisms/ses-managementRecommend'
import SesManagementFunction from '../../components/organisms/ses-managementFunction'
import SesManagementHowto from '../../components/organisms/ses-managementHowto'
import SesManagementOptionPrice from '../../components/organisms/ses-managementOptionPrice'
import SesManagementMerit from '../../components/organisms/ses-managementMerit'
import SesManagementFaq from '../../components/organisms/ses-managementFaq'

const Wrapper = styled.main``

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 機能紹介ページ[SES案件管理]
 * @constructor
 */
const SesManagement = ({ location }: IProps) => (
  <OfficialLayout location={location} className="p-page-function">
    <SEO title="SES案件管理機能" />
    <Wrapper>
      <OfficialLowHeader
        label="SES案件管理機能"
        description="SES案件に関する情報管理、SES営業のための営業支援ツール。"
      />
      <SesManagementTop />
      <SesManagementRecommend />
      <SesManagementMerit />
      <SesManagementFunction />
      <SesManagementOptionPrice />
      <SesManagementHowto />
      <SesManagementFaq />
    </Wrapper>
  </OfficialLayout>
)

export default SesManagement

export const pageQuery = graphql`
  query SesManagement {
    site {
      siteMetadata {
        title
      }
    }
  }
`
