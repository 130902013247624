import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'

const Wrapper = styled.section`
  width: 100%;
  font-weight: bold;
  background-color: #fff;
  margin-bottom: 60px;

  .top-explanation {
    font-weight: normal;
    margin-bottom: 80px;
  }

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0 80px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    overflow: hidden;

    .p-section-function {
      padding: 20px 0;
      border-radius: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
    }

    .bg-agent-function {
      width: 100%;
      background-image: url(/images/official/function/bgAgentMode01.png);
      background-size: cover;
      overflow: hidden;
      z-index: -1;
    }

    .bg-agent-function:before {
      content: '';
      background: inherit;
      background-color: rgba(255, 255, 255, 0.3);
      background-blend-mode: lighten;
      filter: blur(5px);
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      z-index: -1;
    }

    // おすすめ要素

    .recommend-area {
      display: flex;
      justify-content: center;
      align-items: center;
      filter: drop-shadow(0px 3px 6px #666);
    }

    .circle-area:nth-of-type(1) {
      margin-right: -10px;
    }

    .circle-area:nth-of-type(2) {
      margin-left: -10px;
    }

    .circle-area {
      background-color: #FFFFFF;
      height: 480px;
      width: 480px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text-area {
        height: 120px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-size: 28px;

          span {
            background: linear-gradient(transparent 60%, rgb(255, 255, 102) 60%);
            padding: 0 5px;
          }
        }
      }

      img {
        height: 170px;
        width: auto;
      }
    }

    ${BreakPoints.xxLarge} {
    }

    ${BreakPoints.xLarge} {
      .circle-area {
        background-color: #FFFFFF;
        height: 360px;
        width: 360px;

        .text-area {
          height: 90px;
          margin-bottom: 10px;

          p {
            font-size: 21px;
          }
        }

        img {
          height: 127px;
          width: auto;
        }
      }
    }

    ${BreakPoints.medium} {
      .circle-area {
        background-color: #FFFFFF;
        height: 240px;
        width: 240px;

        .text-area {
          height: 60px;
          margin-bottom: 10px;

          p {
            font-size: 14px;
          }
        }

        img {
          height: 85px;
          width: auto;
        }
      }
    }
`

/**
 * 案件管理機能紹介ページ-こんな企業におすすめ
 * @constructor
 */
const SesManagementRecommend = () => (
  <Wrapper>
    <div className="p-section-inner fade-in-auto">
      <OfficialCommonH3Set
        label="こんな企業におすすめ"
        className="fade-in-auto"
      />
      <div className="top-explanation">
        <p>
          契約期間や単価情報などSES案件に関する様々なデータをキンクラ上で容易に管理することができます。
        </p>
      </div>
      <div className="p-section-function fade-in-auto">
        <div className="bg-agent-function" />
        <div className="recommend-area">
          <div className="circle-area">
            <div className="text-area">
              <p>
                案件情報を
                <br />
                <span>Excelやスプレッドシート</span>で
                <br />
                管理している
              </p>
            </div>
            <img
              src="/images/official/function/sesManagementRecommend01.png"
              alt="キンクラ案件管理機能"
            />
          </div>
          <div className="circle-area">
            <div className="text-area">
              <p>
                単価交渉を進めたいが
                <br />
                <span>営業担当の動きが鈍い</span>
              </p>
            </div>
            <img
              src="/images/official/function/sesManagementRecommend02.png"
              alt="キンクラ案件管理機能"
            />
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default SesManagementRecommend
