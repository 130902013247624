import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import SesManagementFunctionArea from './ses-managementFunctionArea'

const Wrapper = styled.section`
  width: 100%;
  background: #deeff0;
  // overflow: hidden;
  padding: 80px 0;
  font-weight: bold;
  position: relative;
  .background {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    // 背景画像
    img {
      border-style: none;
      width: 100%;
      height: auto;
      mix-blend-mode: soft-light;
    }
  }
  .p-section-inner {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}  }

  ${BreakPoints.xxLarge} {
  }

  ${BreakPoints.large} {
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;
      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;
          .description {
          }
        }
      }
    }
  }
`

/**
 * 案件管理機能紹介ページ-主な機能
 * @constructor
 */
const SesManagementFunction = () => (
  <Wrapper>
    <OfficialCommonH3Set label="主な機能" />
    <div className="background">
      <img src="/images/official/function/bg.png" alt="キンクラ-案件管理機能" />
    </div>
    <div className="p-section-inner">
      <SesManagementFunctionArea
        img="sesManagementFunction01"
        icon="sesIcon01"
        label="案件情報の管理"
      >
        取引先や契約期間、単価などの
        <br />
        多岐にわたる案件情報を登録できます
      </SesManagementFunctionArea>
      <SesManagementFunctionArea
        img="sesManagementFunction02"
        icon="sesIcon02"
        label="契約更新通知"
      >
        契約更新日が近づくとお知らせされ、
        <br />
        単価アップなどの契約更新も容易です{' '}
      </SesManagementFunctionArea>
      <SesManagementFunctionArea
        img="sesManagementFunction03"
        icon="sesIcon03"
        label="営業情報の共有"
      >
        全社共有メモ、部門限定の共有メモは
        <br />
        一覧表上で簡単に更新できます{' '}
      </SesManagementFunctionArea>
      <SesManagementFunctionArea
        img="sesManagementFunction04"
        icon="sesIcon04"
        label="単価管理"
      >
        経験年数/年齢に応じた最低単価や
        <br />
        目標単価を設定でき 目標未達の案件を
        <br />
        洗い出すことができます
      </SesManagementFunctionArea>
      <SesManagementFunctionArea
        img="sesManagementFunction05"
        icon="sesIcon05"
        label="データ分析"
      >
        会社全体の売上や取引先、 部門単位の売上、個人の単価推移など
        グラフィカルな表示で売上傾向を 直感的に把握できます
      </SesManagementFunctionArea>
    </div>
  </Wrapper>
)

export default SesManagementFunction
