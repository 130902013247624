import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 30px 80px;

  .image-wrap {
    width: 220px;
    height: 140px;
    z-index: 3;

    img {
      width: inherit;
      height: inherit;
    }
  }

  .text-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      z-index: 2;
      font-size: 23px;
      font-weight: bold;
      color: #09485c;
      margin-top: 10px;
      width: max-content;
      white-space: pre-wrap;
      min-width: 250px;
      text-align: center;
    }

    .hexagon {
      opacity: 0.4;
      position: absolute;
      top: -25px;
      left: -15px;
      z-index: -1;
    }

    .hexagon::before {
      content: '';
      width: 250px;
      position: absolute;
      top: 0;
      border-bottom: 60px solid #a0cbbf;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      box-sizing: border-box;
    }

    .hexagon::after {
      content: '';
      width: 250px;
      position: absolute;
      top: 59.7px;
      border-top: 60px solid #a0cbbf;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      box-sizing: border-box;
    }
  }

  ${BreakPoints.xLarge} {
  }

  ${BreakPoints.large} {
  }

  ${BreakPoints.medium} {
  }

  ${BreakPoints.smallest} {
  }
`

type Props = {
  /** タイトル */
  label: string
  /** アイコン */
  img: string
}

/**
 * 案件管理機能紹介ページ-TOP部アイテムコンポーネント
 * @constructor
 */

const SesManagementTopItem = (props: Props): JSX.Element => {
  const { label, img } = props

  return (
    <Wrapper className="section item-area">
      <div className="image-wrap">
        <img
          src={`/images/official/function/${img}.png`}
          alt="キンクラ_SES案件管理機能"
        />
        <div className="text-wrap">
          <p>{label}</p>
          <div className="hexagon" />
        </div>
      </div>
    </Wrapper>
  )
}

export default SesManagementTopItem
