import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 0 60px;

  .h3 {
    font-size: 2.5em;
    margin: 0 0 20px;
    white-space: pre-wrap;
    font-weight: bold;
  }

  p {
    white-space: pre-wrap;
    font-weight: 600;
    opacity: 0.6;
  }

  .p-h3-line {
    width: 48px;
    height: 4px;
    margin: 20px auto;
    position: relative;

    &:before,
    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: #3ec7b3;
      top: 0;
    }

    &:after {
      left: 8px;
    }

    span {
      display: block;
      background: #3ec7b3;
      width: 32px;
      margin-left: auto;
      height: 4px;
      border-radius: 2px;
    }
  }

  ${BreakPoints.medium} {
    .h3 {
      font-size: 2em;
    }

    margin: 0 auto 30px;
    width: calc(100vw - 80px);
    word-break: break-word;

    p {
      width: 70%;
      margin: 0 auto;
    }
  }

  ${BreakPoints.smallest} {
    p {
      width: 260px;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** タイトル */
  label?: string
  /** 補足テキスト */
  description?: string
}

/**
 * 共通H3タグ:h3→divタグへ変更
 * @param props
 * @constructor
 */
const OfficialCommonH3Set = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={className}>
      <div className="h3">{props.label}</div>
      <p>{props.description}</p>
      <div className="p-h3-line">
        <span />
      </div>
    </Wrapper>
  )
}

export default OfficialCommonH3Set
