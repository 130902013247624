import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import BreakPoints from '../../styles/breakPoints'

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin: 150px 0;
  .text-wrap {
    position: relative;
    flex: 1;
    color: #1d4a5d;
    padding: 0 30px;
    white-space: pre-wrap;
    p {
      font-size: 20px;
      opacity: 0.7;
    }
  }
  .number-wrap {
    position: absolute;
    top: -140px;
    left: 10px;
    z-index: -1;
    opacity: 0.1;
    p {
      font-size: 170px;
    }
  }
  .label-area h3 {
    font-weight: bolder;
    font-size: 34px;
    margin-bottom: 20px;
    white-space: pre-wrap;
    text-shadow: 0 3px 1px rgb(235, 255, 0, 0.4);
  }

  .image-area {
    flex: 1.2;
    height: fit-content;
    padding: 0 20px;
    position: relative;
    img {
      width: 100%;
    }
  }

  // 吹き出し
  .merit-comment {
    z-index: 2;
    position: absolute;
    height: 260px;
    width: 260px;
    border-radius: 130px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // filter: drop-shadow(0px 3px 6px #666);
    .comment-icon {
      height: 70px;
      width: fit-content;
      margin-bottom: 15px;
      img {
        height: inherit;
      }
    }
    .comment-text {
      text-align: center;
      white-space: pre-wrap;
      z-index: 2;
    }
  }
  .image-right {
    .merit-comment {
      top: -75px;
      right: -20px;
      .tail {
        position: absolute;
        bottom: 0px;
        left: 0px;
        content: '';
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 35px solid transparent;
        border-top: 50px solid #fff;
        margin-top: -5vw;
        transform: rotate(45deg);
      }
    }
  }
  .image-left {
    .merit-comment {
      top: -75px;
      left: -20px;
      .tail {
        position: absolute;
        bottom: 0px;
        right: 0px;
        content: '';
        width: 0;
        height: 0;
        border-right: 15px solid transparent;
        border-left: 35px solid transparent;
        border-top: 50px solid #fff;
        margin-top: -5vw;
        transform: rotate(-45deg);
      }
    }
  }

  // 吹き出しの影
  .merit-shadow {
    position: absolute;
    height: 260px;
    width: 260px;
    border-radius: 130px;
    background-color: #ababab;
    filter: drop-shadow(0px 3px 6px #ababab);
    mix-blend-mode: multiply;
  }
  .image-left {
    .merit-shadow {
      top: -75px;
      left: -20px;
      .tail {
        position: absolute;
        bottom: 0px;
        right: 0px;
        content: '';
        width: 0;
        height: 0;
        border-right: 15px solid transparent;
        border-left: 35px solid transparent;
        border-top: 50px solid #ababab;
        margin-top: -5vw;
        transform: rotate(-45deg);
      }
    }
  }
  .image-right {
    .merit-shadow {
      top: -75px;
      right: -20px;
      .tail {
        position: absolute;
        bottom: 0px;
        left: 0px;
        content: '';
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 35px solid transparent;
        border-top: 50px solid #ababab;
        margin-top: -5vw;
        transform: rotate(45deg);
      }
    }
  }

  ${BreakPoints.xLarge} {
    .text-wrap {
      p {
        font-size: 14px;
      }
    }
    .number-wrap {
      top: -100px;
      left: 20px;
      p {
        font-size: 120px;
      }
    }
    .label-area h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .merit-shadow {
      height: 160px;
      width: 160px;
      border-radius: 80px;
      .tail {
      }
    }
    .merit-comment {
      height: 160px;
      width: 160px;
      border-radius: 80px;
      .tail {
      }
      .comment-icon {
        height: 50px;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
      }
    }
  }

  ${BreakPoints.large} {
    .text-wrap {
      padding: 0 15px;
    }
    .number-wrap {
      top: -100px;
      left: -10px;
    }
  }
  ${BreakPoints.medium} {
    flex-direction: column;
    margin: 100px 0;
    .text-wrap {
      padding: 0 30px 30px 15px;
    }
    .merit-shadow {
      top: -10px !important;
      right: -20px !important;
      left: auto !important;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      .tail {
      }
    }
    .merit-comment {
      top: -10px !important;
      right: -20px !important;
      left: auto !important;
      height: 120px;
      width: 120px;
      border-radius: 60px;
      .tail {
        left: 0px !important;
        border-right: 15px solid transparent;
        border-left: 35px solid transparent;
        border-top: 50px solid #ababab;
        margin-top: -5vw;
        transform: rotate(-45deg);
      }
      .comment-icon {
        display: none;
      }
      p {
        font-size: 8px;
      }
    }
  }
  ${BreakPoints.smallest} {
  }
`

type Props = {
  /** タイトル */
  label: string
  /** 内容 */
  children: ReactNode
  /** メリット番号 */
  number: string
  /** スクリーンショット */
  img: string
  /** 吹き出し内アイコン */
  icon: string
  /** 吹き出し内テキスト */
  merit: string
  /** 右に画像 */
  imageRight?: boolean
  /** 左に画像 */
  imageLeft?: boolean
}

/**
 * 案件管理機能紹介ページ-導入メリット_メリット1つ分コンポーネント
 * @constructor
 */

const SesManagementMeritArea = (props: Props): JSX.Element => {
  const {
    children,
    label,
    number,
    img,
    imageRight,
    imageLeft,
    icon,
    merit = '',
  } = props

  return (
    <Wrapper className="anim-fade-up merit-area">
      {imageLeft && (
        <div className="image-area image-left">
          <img
            src={`/images/official/function/${img}.png`}
            alt="キンクラ_SES案件管理機能"
          />
          {/** 吹き出しの影 */}
          <div className="merit-shadow">
            <div className="tail" />
          </div>
          {/** 吹き出し */}
          <div className="merit-comment">
            <div className="tail" />
            <div className="comment-icon">
              <img
                src={`/images/official/function/${icon}.png`}
                alt="キンクラ_SES案件管理機能"
              />
            </div>
            <p className="comment-text">{merit}</p>
          </div>
        </div>
      )}
      <div className="text-wrap">
        <div className="number-wrap">
          <p>{number}</p>
        </div>
        <div className="text-area">
          <div className="label-area">
            <h3>{label}</h3>
          </div>
          <p>{children}</p>
        </div>
      </div>
      {imageRight && (
        <div className="image-area image-right">
          <img
            src={`/images/official/function/${img}.png`}
            alt="キンクラ_SES案件管理機能"
          />

          {/** 吹き出しの影 */}
          <div className="merit-shadow">
            <div className="tail" />
          </div>
          {/** 吹き出し */}
          <div className="merit-comment">
            <div className="tail" />
            <div className="comment-icon">
              <img
                src={`/images/official/function/${icon}.png`}
                alt="キンクラ_SES案件管理機能"
              />
            </div>
            <p className="comment-text">{merit}</p>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default SesManagementMeritArea
