import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import SesManagementOptionPriceTable from './ses-managementOptionPriceTable'

const Wrapper = styled.section`
  width: 100%;
  background: #f0f0f0;
  overflow: hidden;
  padding: 80px 0;
  font-weight: bold;

  .p-section-inner {
    max-width: 910px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
    // overflow: hidden;
  }

  .img-agent-function {
    margin-top: 40px;
  }

  .margin-bottom {
    margin-bottom: 0;
  }

  .ribbon {
    margin: 30px 0;
    display: inline-block;
    position: relative;
    height: 26px;
    line-height: 26px;
    text-align: center;
    padding: 0 30px;
    font-size: 18px;
    background: #ff5c9a;
    color: #fff;
    box-sizing: border-box;
  }

  .ribbon:before,
  .ribbon:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    z-index: 1;
  }

  .ribbon:before {
    top: 0;
    left: 0;
    border-width: 13px 0 13px 10px;
    border-color: transparent transparent transparent #f0f0f0;
    border-style: solid;
  }

  .ribbon:after {
    top: 0;
    right: 0;
    border-width: 13px 10px 13px 0;
    border-color: transparent #f0f0f0 transparent transparent;
    border-style: solid;
  }

  .caution-text {
    font-weight: normal;
    text-align: start;
    margin-top: 20px;
    white-space: pre-wrap;
  }

  ${BreakPoints.large} {
    p {
      font-size: 12px;
    }

    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * 案件管理機能紹介ページ-オプション利用料金
 * @constructor
 */
const SesManagementOptionPrice = () => (
  <Wrapper id="ses-management-option-price">
    <OfficialCommonH3Set label="オプション利用料金" className="margin-bottom" />
    <div className="p-section-inner">
      <div className="img-agent-function">
        {/* <div className="ribbon"> */}
        {/*   <p>2023年12月末まではβ版のため、無料でご利用いただけます</p> */}
        {/* </div> */}
        <SesManagementOptionPriceTable />
        <div className="caution-text">
          <p>
            {'※上記利用料金は税抜価格です。\n' +
              '※Businessプランでは、パートナー/個人事業主は100人まで登録可能です。\n' +
              '※上記利用料金や内容は予告なく変更になる可能性があります。予めご了承ください。'}
          </p>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default SesManagementOptionPrice
